import styled from 'styled-components'

const MyDiv = styled.div`
.fc-more-popover-misc{
    display: none;
}
.add-btn {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 50%;
    padding: 4px;
    font-size: 10px;
    cursor: pointer;
  }
  
.add_promp{
    font-weight: 600;
    font-size: 0.7rem;
    font-family: 'Poppins',sans-serif;
}
.dateBox{
    position: relative;
}
.fc .fc-daygrid-day-top{
    flex-direction: row;
}
.fc-timegrid-slot{
    height: 100px;
    cursor: pointer;
}

.fc-v-event{
    height:65px;
    border:none!important;
    padding: 3px 0px 0px 5px;
    margin: 1px 0px 0px 6px;
}
.scheduled{
    background-color:#283c86!important;
}
.archive{
    background-color:#62a832!important;
}
.fc .fc-popover{
    z-index: 1;
}
.fc-scroller{
    position:relative!important;
}
.fc-timegrid-event-harness{
    width: 100%;
    height: auto;
    position: relative !important;
    left: 0% !important;
    margin-right: 0% !important;
}
.fc-direction-ltr .fc-timegrid-more-link{
    display: inline-table;
    margin-top: 70px;
}
.event_info h5{
    white-space: break-spaces;
    color: #fff;
    font-weight: 600;
    font-size: 0.7rem;
    font-family: 'Poppins',sans-serif;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical
}
.event_info p{
    color: #fff;
    font-weight: 600;
    font-size: 0.7rem;
    font-family: 'Poppins',sans-serif;
    text-transform: capitalize;
}
.event_list li{
    padding:8px 0 0;
    max-width:70%;
}
.event_list .event_img img{
    width:35px;
    height:35px;
}
.event_list{
    width: 100%;
}
.event_img {
   margin-right:4px;
   min-width:auto;
}
.action_icon{
    width: 40px;
    height: 40px;
    background: transparent;
    color: #fff;
    margin-left: 0px;
}
fc-timegrid-bg-harness:hover{
    background-color: green;
}
.fc-button-group .fc--button{
    display:none;
}
.fc-timeGridWeek-button{
    border-radius: 0.25em!important;
}
.fc-toolbar-title{
    font-family: 'Poppins', sans-serif!important;
    font-size:1.4rem;
    @media(max-width:767px){
        font-size:1.2rem;
    }
}
.share_icon{
    color:#fff;
}
.action_events{
    display:flex;
    flex-direction:column;
    max-width:30%;
    position:absolute;
    top:4px;
    right:4px;
    row-gap:10px;
}
.action_events svg{
    width: 1rem;
    height: 1rem;
}
.reshare_events{
    max-width:30%;
    position:absolute;
    top:35%;
    right:4px;
}
.css-1hnm6b6 {
    margin-top: 103px;
}
  .css-1d6wzja-MuiButton-startIcon{
    margin-left : 0px !important;
    margin-right: 0px !important;
  }
  .fc-day-future:hover .showIcon svg{
    display: block;
  }
  .fc-day-today:hover .showIcon svg{
    display: block;
  }
  .fc-day-past{
    background-color: #d3d3d338;;
  }
  .fc-daygrid-day {
    height: 150px!important;
  }
  .showIcon{
    height: 40px;
    padding: 16px;
    position: absolute;
    bottom: 4px;
    left: 108px;
}
.hourBox{
    text-align: center;
}
.hourBox svg{
    color: #283c86;
    border: 1px solid #283c86;
    border-radius: 8px;
}
}
.hourBox button{
    height: 100px!important;
}

.fc-day-disabled{
    background: #fff;
}

.AddIcon{
    border: none;
    background: none;
}
.AddIcon:hover{
    border: none;
    background: none;
}
.AddIcon svg{
    display: none;
    color: #283c86;
    border: 2px solid #283c86;
    border-radius: 10px;
}
.fc .fc-popover{
    height: 200px;
    overflow-y: scroll;
}
.fc-theme-standard .fc-popover-header{
    background: #ddd;
}
.fc .fc-more-popover .fc-popover-body{
    background: #fff;
}
.fc-button-group button{
    border: none;
    background: #283c86;
}
.fc-button-group button:hover{
    border: none;
    background: linear-gradient(to right,#2a2653,#ef305e);
}
.fc-prev-button {
    margin-right: 10px;
  }
  .fc-next-button {
    margin-left: 10px;
  }
  .fc-createPostButton-button:hover{
    border: none;
    background: #283c86;
  }
  
  .fc-createPostButton-button{
    border: none;
    background: #ef305e;
  }
`
export default MyDiv
