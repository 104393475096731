import {React, useState} from 'react'
// import TextField from '@mui/material/TextField'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {StaticDatePicker} from '@mui/x-date-pickers/StaticDatePicker'
import moment from 'moment'
import {Popover, Box} from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import {CustomButton} from '..'
import TimePicker from './timePicker.js'
import MyDiv from './customDateTimePicker.style'

export default function CustomDateTimePicker(props) {
  const [open, setOpen] = useState(false)
  const [btnEL, setBtnEL] = useState()
  const handleBtnClick = (e) => {
    setBtnEL(e.currentTarget)
    setOpen(true)
  }
  // const handleChange = (e) => {
  //   props.setValue(moment(e).format('DD-MM-YYYY'))
  // }
  function changeTimeZone(date, timeZone) {
    if (typeof date === 'string') {
      return new Date(
        new Date(date).toLocaleString('en-US', {
          timeZone,
        }),
      )
    }

    return new Date(
      date.toLocaleString('en-US', {
        timeZone,
      }),
    )
  }

  const laDate = changeTimeZone(new Date(), props?.timeZone?.timezone ? props?.timeZone?.timezone : 'Asia/Kolkata')
  return (
    <MyDiv>
      <Box className="dateTime_btn">
        <CustomButton variant="outlined" onClick={handleBtnClick} className="text-font action_button_date_time"
          fieldlabel={props.value ? moment(`${props.value} ${props.hours} ${props.minutes}`, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : 'Schedule'}
        />
        {props.value &&
        <RefreshIcon onClick={props.handleResetScheduleData} />
        }
      </Box>
      <Popover
        id={Date.now()}
        open={open}
        anchorEl={btnEL}
        onClose={() => setOpen(false)}
        PaperProps={{
          elevation: 0,
          sx: {
            'overflow': 'visible',
            'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            'mt': 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 50,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        sx={{
          '& .MuiPaper-root': {
            overflow: 'unset',
            marginTop: '1rem',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            openTo="day"
            value={props.value ? moment(props.value).format('DD-MM-YYYY') : ''}
            minDate={laDate}
            allowSameDateSelection
            onChange={props.handleScheduler}
            // onChange={(e) => handleChange(e)}
            // renderInput={(params) => <TextField {...params} />}
          />
          <TimePicker
            handleTimeZoneChange={props.handleTimeZoneChange}
            timeZone={props.timeZone}
            handleHours={props.handleHours}
            hours={props.hours}
            handleMinutes={props.handleMinutes}
            minutes={props.minutes}
          />
        </LocalizationProvider>
      </Popover>
    </MyDiv>
  )
}
