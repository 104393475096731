import React, {useEffect, useState} from 'react'
import {Box, Grid, Typography} from '@mui/material'
import {connect, useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {bindActionCreators} from 'redux'
import {compose} from 'recompose'
import {AddImage} from '../ProductGallery/components'
import {SocialMediaSelect, ProductGallery, RichTextEditor, PostTitleTextBox} from '..'
import {getToken} from '../../../../utilities/authUtils'
import {ActionCreators} from '../../../../redux/actions'
import MyDiv from './productDetailsLeftPanel.style'
function ProductDetailsLeftPanel(props) {
  console.log(props, 'shailesh propsDetailLeftPannel')
  const userState = useSelector((state) => state.userState)
  const postState = useSelector((state) => state?.postState)
  const {addPostData, getPosition} = useSelector((state) => state.postState)
  const storeName = useState(userState?.getStore?.data?.payload.filter((store) => store.id === getToken('selectedStore'))[0]?.shop_name)
  useEffect(() => {
    if (props.productDetailType === 'Reshare') {
      props.actions.onChangeSocialMediaAction(postState?.addPostData?.map(
        (shareholder, sidx) => {
          if (postState?.getPosition !== sidx) {return shareholder} else {
            return {...shareholder, image: [].concat(postState.getSpecificAchiveData?.data?.payload?.master_image),
            // title: postState.getSpecificAchiveData?.data?.payload?.title,
            }
          }
        }))
    }
    props.actions.onChangeSocialMediaAction(postState?.addPostData?.map(
      (shareholder, sidx) => {
        if (postState?.getPosition !== sidx) {return shareholder} else {
          return {...shareholder, image: [].concat(postState.getSpecificPostedData?.data?.payload?.master_image),
          // title: postState.getSpecificAchiveData?.data?.payload?.title,
          }
        }
      }))
  }, [postState.getSpecificAchiveData, postState.getSpecificPostedData])
  // getSpecificPostedData
  return (
    <MyDiv>
      {/* <pre>{JSON.stringify(props.socialMediaData)}</pre> */}
      {/* {console.log(props.productData, 'boolean product data select contents')} */}
      <Box>
        <Grid container className="product_grid" columnSpacing={{xs: 2, sm: 2, md: 2}}>
          <Grid item xs={12} md={12}>
            <Box mb={3} sx={{position: 'relative'}}>
              <Typography
                variant="h6"
                color="text.primary"
                className="text-font store_name"
              >
                {storeName}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography
                variant="h6"
                color="text.primary"
                fontSize="0.9rem"
                className="text-font"
              >
                {props.productData.product_name}
              </Typography>
            </Box>
            <SocialMediaSelect
              socialMediaData={props.socialMediaData}
              productData={props.productData}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <PostTitleTextBox />
      </Box>
      <Box mt={3}>
        <Grid container className="product_grid" columnSpacing={{xs: 2, sm: 1, md: 1}}>
          <Grid item xs={12} md={12}>
            <Box>
              <RichTextEditor
                productData={props.productData}
                setDescriptionPost={props.setDescriptionPost}
                handleDescription={props.handleDescription}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <AddImage
          images={props?.productData?.images}
          selected={addPostData[getPosition]?.image}
          imfuleCloudData={props.imfuleCloudData}
        />
      </Box>
      <ProductGallery
        images={props?.productData?.images}
        selected={addPostData[getPosition]?.image}
        imfuleCloudData={props.imfuleCloudData}
      />
    </MyDiv>
  )
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(ProductDetailsLeftPanel)
