import React, {useEffect, useRef, useState} from 'react'
import {Grid, Box, Dialog, DialogContent, DialogContentText, Slide, CardActionArea, IconButton, Divider} from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import {connect, useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {bindActionCreators} from 'redux'
import SimpleReactValidator from 'simple-react-validator'
import {compose} from 'recompose'
import PropTypes from 'prop-types'
import {Close as CloseIcon} from '@mui/icons-material'
import InventoryIcon from '@mui/icons-material/Inventory'
import {AddCustomStoreSidebar, Loader, Snackbar} from '..'
import Shopify from '../../images/shopify.webp'
import Prestashop from '../../images/prestashop.webp'
import Woocommerce from '../../images/woo.webp'
import Magento from '../../images/magento.webp'
import {ActionCreators} from '../../redux/actions'
import {getToken} from '../../utilities/authUtils'
import {PrestashopStepsDialog, StoreAddress, PrestashopStoreAddress} from './components'
import MyDiv from './storeListingDialog.style'
const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

function StoreListingDialog(props) {

  const imageRef = useRef()
  const userState = useSelector((state) => state.userState)
  const masterState = useSelector((state) => state.masterState)
  const customStoreState = useSelector((state) => state?.customStoreState)
  const [, forceUpdate] = useState()
  const simpleValidator = useRef(new SimpleReactValidator())
  const [status, setStatus] = useState('')
  const [message, setMessage] = useState('')
  const [openShopify, setOpenShopify] = useState(false)
  const [openSteps, setOpenSteps] = useState(false)
  const [storeAddress, setStoreAddress] = useState('')
  const [prestaShopData, setPrestaShopData] = useState({
    storeName: '',
    storeUrl: '',
    key: '',
  })
  const [openPrestashop, setOpenPrestashop] = useState(false)
  const [openCustomSidebar, setOpenCustomSidebar] = useState(false)
  const [importFile, setImportFile] = useState(null)
  const [timeZone, setTimeZone] = useState(null)
  const [inputValues, setInputValues] = useState({
    storeName: '',
    storeUrl: '',
  })
  const [importFileCustom, setImportFileCustom] = useState('')
  const timeZoneData = masterState?.timeZoneList?.data?.payload?.map((data) => {
    return data
  })

  // SETTING STATE FOR INPUT FIELDS
  const onChangeInput = (type) => (event) => {
    setInputValues({
      ...inputValues,
      [type]: event.target.value})
  }
  const showOpenFileDialog = () => {
    imageRef.current.click()
  }

  const handleChangeFile = (e) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    setImportFile(e.target.files[0])
    console.log(e.target.files[0], 'FormData')
  }

  // SETTING STATE FOR TIMEZONE
  const handleTimeZoneChange = (val) => {
    setTimeZone(val)
  }

  const handleFileUpload = (event) => {
    const formData = new FormData()
    formData.append('file', event.target.files[0])
    setImportFileCustom(event.target.files[0])
  }
  const handleCustomSidebar = (val) => {
    setOpenCustomSidebar(val)
    props.handleListing()
  }
  // ADDING CUSTOM STORE HERE:
  const handleAddStoreCustom = () => {
    if (simpleValidator.current.allValid()) {
      const data = new FormData()
      data.append('storeImport', importFileCustom)
      data.append('storeName', inputValues.storeName)
      data.append('storeUrl', inputValues.storeUrl)
      data.append('storeTimezone', timeZone?.id ?? '')
      props.actions.addCustomStoreAction(data)
    } else {
      simpleValidator.current.showMessages(true)
      forceUpdate(1)
    }
  }
  //SHAILESH USEEFFECT
  useEffect(() => {
    if (customStoreState?.addCustomStore?.data?.meta?.status === 'success') {
      setStatus(customStoreState?.addCustomStore?.data?.meta?.status)
      setMessage(customStoreState?.addCustomStore?.data?.meta?.message)
      handleCustomSidebar(false)
      setInputValues('')
      setTimeZone('')
      setImportFile({})
      props.actions.cleanUpCustomStoreState()
    }
  }, [customStoreState?.addCustomStore?.data])
  const handlePrestashop = () => {
    setOpenPrestashop(false)
    props.actions.cleanUpStateStore()
    setPrestaShopData({
      storeName: '',
      storeUrl: '',
      key: '',
    })
  }


  const handlePrestaShopChange = (prop) => (event) => {
    setPrestaShopData({
      ...prestaShopData,
      [prop]: event.target.value,
    })
  }
  const handleShopify = () => {
    setOpenShopify(false)
    setStoreAddress('')
    props.actions.cleanUpStateStore()
  }

  const handleSteps = () => {
    setOpenSteps(false)
    setOpenPrestashop(true)
  }

  const handleNextPrestashop = () => {
    setOpenSteps(true)
    props.handleListing()
  }


  // empty the state values and close the custom side bar
  const closeCustomSidebar = () => {
    handleCustomSidebar(false)
    setInputValues('')
    setTimeZone('')
    setImportFileCustom({})
  }
  const handleNextShopify = () => {
    window.location.href = getToken('setting')?.SHOPIFY_GENERAL_SETTINGS?.shopify_store_url
  }
  const handleAddStore = (shopType) => {
    if (shopType === 'shopify') {
      let data = {
        shop_url: storeAddress,
      }
      props.actions.addStoreAction(shopType, data)
    } else if (shopType === 'prestashop') {
      let data = {
        shop_name: prestaShopData?.storeName,
        shop_url: prestaShopData?.storeUrl,
        key: prestaShopData?.key,
      }
      props.actions.addStoreAction(shopType, data)
    }

  }
  const handleStoreAddressChange = (e) => {
    setStoreAddress(e.target.value)
  }
  const handleClosePrestashop = () => {
    setOpenSteps(false)
    props.actions.cleanUpState()
  }
  const handleSnackBarClose = () => {
    setTimeout(() => {
      setMessage('')
      setStatus('')
    }, 2500)
  }
  const listingPopup = () => (
    <MyDiv>
      {props.loading &&
      <div>
        <Loader />
      </div>
      }
      <DialogContent className="no-scroll">
        <DialogContentText id="alert-dialog-slide-description" variant="body1"
          color="#000"
          textAlign="center"
          fontSize="1.3rem"
          fontFamily="Poppins"
          fontWeight="600"
          paddingBottom={'15px'}
        >
          Connect or Add Store
          <IconButton onClick={() => props.handleListing()} className="close_listing">
            <CloseIcon />
          </IconButton>
        </DialogContentText>
        <Divider />
        <Box sx={{width: '100%'}}>
          <Grid container>
            <Grid item md={12} xs={12}>
              <Grid container >
                <Grid item md={12} xs={12}>
                  <Box className="listing_store_box" mt={3} mb={1}>
                    {getToken('setting')?.active_shop?.map((data) => {
                      return (<>
                        {data.slug === 'shopify' &&
                          <Card className="listing_cards">
                            <CardActionArea sx={{background: '#d9dfd2'}} onClick={handleNextShopify}>
                              <div className="store_image">
                                <img src={Shopify} alt="pic" loading="lazy" />
                              </div>
                              <CardContent className="custom_content">
                                <Typography variant="h5" component="div" className="text-font store_names">
                                  Connect Shopify
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        }
                        {data.slug === 'prestashop' &&
                        <Card className="listing_cards">
                          <CardActionArea sx={{background: '#c8e7ef'}} onClick={handleNextPrestashop}>
                            <div className="store_image">
                              <img src={Prestashop} alt="pic" loading="lazy" />
                            </div>
                            <CardContent className="custom_content">
                              <Typography variant="h5" component="div" className="text-font store_names">
                                Connect Prestashop
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                        }
                        {data.slug === 'woocommerce' &&
                        <Card className="listing_cards">
                          <CardActionArea sx={{background: '#e7d9e5'}}>
                            <div className="store_image">
                              <img src={Woocommerce} alt="pic" loading="lazy" />
                            </div>
                            <CardContent className="custom_content">
                              <Typography variant="h5" component="div" className="text-font store_names">
                                Connect Woocommerce
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                        }
                        {data.slug === 'magento' &&
                        <Card className="listing_cards">
                          <CardActionArea sx={{background: '#fdd1c4'}}>
                            <div className="store_image">
                              <img src={Magento} alt="pic" loading="lazy" />
                            </div>
                            <CardContent className="custom_content">
                              <Typography variant="h5" component="div" className="text-font store_names">
                                Connect Magento
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                        }
                        {data.slug === 'custom' &&
                        <Card className="listing_cards">
                          <CardActionArea sx={{background: '#ddd'}} onClick={handleCustomSidebar}>
                            <div className="store_image">
                              <InventoryIcon />
                            </div>
                            <CardContent className="custom_content">
                              <Typography variant="h5" component="div" className="text-font store_names">
                                Add Custom Store
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                        }
                      </>
                      )
                    })}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <PrestashopStepsDialog
        openSteps={openSteps}
        handleSteps={handleSteps}
        handleClosePrestashop={handleClosePrestashop}
      />
      <StoreAddress
        openPopup={openShopify}
        handleShopify={handleShopify}
        handleSubmit={handleAddStore}
        handleChange={handleStoreAddressChange}
        storeAddress={storeAddress}
        error={userState?.addStore?.error?.errors?.shop_url[0]}
        loading={userState?.addStore?.loading}
      />
      <PrestashopStoreAddress
        openStore={openPrestashop}
        handlePrestashop={handlePrestashop}
        handleSubmit={handleAddStore}
        handleChange={handlePrestaShopChange}
        form={prestaShopData}
        error={userState?.addStore?.error?.errors?.shop_url[0]}
        loading={userState?.addStore?.loading}
      />
      <AddCustomStoreSidebar
        handleCustomSidebar={handleCustomSidebar}
        openCustomSidebar={openCustomSidebar}
        timeZoneData={timeZoneData}
        handleTimeZoneChange={handleTimeZoneChange}
        showOpenFileDialog={showOpenFileDialog}
        imageRef={imageRef}
        handleChangeFile={handleChangeFile}
        importFile={importFile}
        customStoreState={customStoreState}
        inputValues={inputValues}
        userState={userState}
        onChangeInput={onChangeInput}
        handleFileUpload={handleFileUpload}
        handleAddStoreCustom={handleAddStoreCustom}
        status={status}
        message={message}
        closeCustomSidebar={closeCustomSidebar}
        importFileCustom={importFileCustom}
        simpleValidator={simpleValidator}
        timeZone={timeZone}
        storeImport={importFileCustom}
      />
    </MyDiv>
  )
  return (
    <>
      {customStoreState?.addCustomStore?.loading &&
      <Loader />
      }
      <Snackbar
        message={message}
        severity={status}
        duration={2500}
        open={message.length > 0}
        close={handleSnackBarClose}
      />
      <Dialog
        popuplistingbox="openListingPopup"
        open={props.openListingPopup}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        onClose={() => props.handleListing()}
        aria-describedby="alert-dialog-slide-description"
      >
        {listingPopup('openListingPopup')}
      </Dialog>
    </>

  )
}
StoreListingDialog.propTypes = {
  openListingPopup: PropTypes.bool,
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(StoreListingDialog)
