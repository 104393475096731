
import React from 'react'
const CustomCardSkeleton = React.lazy(() => import('./CardSkeleton'))
const CustomSchedulingSkeleton = React.lazy(() => import('./SchedulingSkeleton'))
const CustomPostsSkeleton = React.lazy(() => import('./PostsSkeleton'))
const CustomOnBoardingSkeleton = React.lazy(() => import('./OnBoardingSkeleton'))


export {
  CustomCardSkeleton,
  CustomSchedulingSkeleton,
  CustomPostsSkeleton,
  CustomOnBoardingSkeleton,
}
