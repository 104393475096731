import * as actionTypes from '../actions/actionsType'

const INITIAL_STATE = {
  addCustomProduct: {
    data: null,
    loading: false,
    error: null,
  },
}

const customProductReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.USER_ADD_CUSTOM_PRODUCT_REQUEST:
      return {
        ...state,
        addCustomProduct: {
          ...state.addCustomProduct,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.USER_ADD_CUSTOM_PRODUCT_SUCCESS:
      return {
        ...state,
        addCustomProduct: {
          ...state.addCustomProduct,
          data: action.payload,
          loading: null,
          error: null,
        },
      }

    case actionTypes.USER_ADD_CUSTOM_PRODUCT_FAILURE:
      return {
        ...state,
        addCustomProduct: {
          ...state.addCustomProduct,
          data: null,
          loading: null,
          error: action.payload,
        },
      }
    case actionTypes.CLEAN_UP_CUSTOM_PRODUCT_STATE_REQUEST:
      return {
        ...state,
        addCustomProduct: {
          ...state.addCustomProduct,
          data: null,
          loading: false,
          error: null,
        },
      }
    default:
      return state
  }
}

export default customProductReducer
