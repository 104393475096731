/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {Box, CardActions, CardContent, Divider, Drawer, Grid, IconButton, Snackbar, Typography} from '@mui/material'
import {Close as CloseIcon, Upload} from '@mui/icons-material'
import styled from 'styled-components'
import {withRouter} from 'react-router-dom'
import {compose} from 'recompose'
import {connect, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'
import {ActionCreators} from '../../../../redux/actions'
import {CustomButton, CustomTextArea, CustomTextBox, Loader} from '../../../../components'
import {getToken} from '../../../../utilities/authUtils'
import MyDiv from './addProductSidebar.style'

const CustomDrawer = styled(Drawer)(
  ({theme, open}) => ({
    '& .MuiDrawer-paperAnchorLeft': {
      width: '50%',
      [theme?.breakpoints?.up('xs')]: {
        width: '100%',
      },
      [theme?.breakpoints?.up('sm')]: {
        width: '100%',
      },
      [theme?.breakpoints?.up('md')]: {
        width: '50%',
      },
    },
  }),
)


function AddProductSidebar(props) {
  const customProductState = useSelector((state) => state?.customProductState)

  const storeDetails = (anchor) => (
    <MyDiv>
      <Box className="sidebar-inner">
        <Grid container>
          <Grid item md={12} xs={12}>
            <CardContent className="content-body">
              <Grid container alignItems="center" className="edit_grid">
                <Grid item xs={8} md={6} sm={6}>
                  <Typography variant="h6" color="#000" fontSize="1.3rem" fontWeight="600" className="text-font" >
                    Add Product Details
                  </Typography>
                </Grid>
                <Grid item xs={0} md={6} sm={6} display={{xs: 'none', lg: 'block', sm: 'block'}}>
                  <CardActions className="set-right">
                    <CustomButton fieldlabel="Save" onClick={() => props.handleProduct()} variant="contained" className="text-font action_button" />
                    <Box className="close_icon">
                      <IconButton onClick={() => props.closeCustomSidebar()} color="primary">
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </CardActions>
                </Grid>
                <Grid item xs={4} display={{xs: 'block', lg: 'none', sm: 'none'}}>
                  <CardActions className="mobile_icon">
                    <IconButton onClick={() => props.handleCustomSidebar()} color="primary">
                      <CloseIcon />
                    </IconButton>
                  </CardActions>
                </Grid>
              </Grid>
              <Divider />
              <Box sx={{width: '100%'}} mt={4}>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    <form encType="multipart/form-data" >
                      <Grid container>
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant="h6" color="text.primary" fontSize="0.9rem" className="text-font" >
                              Product Name
                            </Typography>
                            <CustomTextBox
                              type="text"
                              name="productName"
                              value={props?.inputValues?.productName ?? ''}
                              onChange={(e) => props.onChangeInput('productName', e)}
                              onBlur={() => props.simpleValidator.current.showMessageFor('productName')}
                              error={props.simpleValidator.current.message('productName', props?.inputValues?.productName, 'required') ||
                              customProductState?.addCustomProduct?.error?.errors?.productName}
                              helperText={props.simpleValidator.current.message('productName', props?.inputValues?.productName, 'required') ||
                              customProductState?.addCustomProduct?.error?.errors?.productName}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant="h6" color="text.primary" fontSize="0.9rem" className="text-font" >
                              Product Description
                            </Typography>
                            <CustomTextArea
                              minRows={3}
                              name="productDescription"
                              value={props?.inputValues?.productDescription ?? ''}
                              onChange={(e) => props.onChangeInput('productDescription', e)}
                              onBlur={() => props.simpleValidator.current.showMessageFor('productName')}
                              error={props.simpleValidator.current.message('productDescription', props?.inputValues?.productDescription, 'required') ||
                              customProductState?.addCustomProduct?.error?.errors?.productDescription}
                              helperText={props.simpleValidator.current.message('productDescription', props?.inputValues?.productDescription, 'required') ||
                              customProductState?.addCustomProduct?.error?.errors?.productDescription}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant="h6" color="text.primary" fontSize="0.9rem" className="text-font" >
                              Product Url
                            </Typography>
                            <CustomTextBox
                              type="text"
                              name="productUrl"
                              value={props?.inputValues?.productUrl ?? ''}
                              onChange={(e) => props.onChangeInput('productUrl', e)}
                              error={props.simpleValidator.current.message('productUrl', props?.inputValues?.productUrl, 'required') ||
                              customProductState?.addCustomProduct?.error?.errors?.productUrl}
                              helperText={props.simpleValidator.current.message('productUrl', props?.inputValues?.productUrl, 'required') ||
                              customProductState?.addCustomProduct?.error?.errors?.productUrl}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant="h6" color="text.primary" fontSize="0.9rem" className="text-font" >
                              Product Price
                            </Typography>
                            <CustomTextBox
                              type="text"
                              name="productPrice"
                              value={props?.inputValues?.productPrice ?? ''}
                              onChange={(e) => props.onChangeInput('productPrice', e)}
                              error={props.simpleValidator.current.message('productPrice', props?.inputValues?.productPrice, 'required') ||
                              customProductState?.addCustomProduct?.error?.errors?.productPrice}
                              helperText={props.simpleValidator.current.message('productPrice', props?.inputValues?.productPrice, 'required') ||
                              customProductState?.addCustomProduct?.error?.errors?.productPrice}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant="h6" color="text.primary" fontSize="0.9rem" className="text-font" >
                              Upload Product Images
                            </Typography>
                            <Box className="import_box">
                              <CustomButton
                                onClick={props.showOpenFileDialog}
                                fieldlabel="Upload"
                                className="text-font action_button btn_color"
                                startIcon={<Upload />}
                                // error={customProductState?.addCustomProduct?.error?.errors?.productImages}
                                // error={props.simpleValidator.current.message('productImages', props?.productImages, 'required')}
                                // helperText={customProductState?.addCustomProduct?.error?.errors?.productImages}
                                // helperText={props.simpleValidator.current.message('productImages', props?.productImages, 'required')}
                              />
                              <input
                                ref={props.imageRef}
                                type="file"
                                style={{display: 'none'}}
                                multiple
                                onChange={(e) => props.handleUploadImage(e)}
                                accept="image/*"
                              />
                              {props.simpleValidator.current.message('productImages', props?.productImage, 'required') || customProductState?.addCustomProduct?.error?.errors?.productImage}
                              {customProductState?.addCustomProduct?.error?.meta?.status === 'failure' && customProductState?.addCustomProduct?.error?.meta?.message}
                            </Box>
                            <Box className="uploaded_image">
                              {props?.productImage?.length > 0 && props.productImage.map((fileName, index) => (
                                <>
                                  <img
                                    key={index}
                                    src={URL.createObjectURL(fileName)}
                                  />
                                </>
                              ))}
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} display={{xs: 'block', lg: 'none', sm: 'none'}}>
                          <CardActions className="set-right">
                            <CustomButton fieldlabel="Cancel" variant="outlined" onClick={() => props.handleCustomSidebar()} className="text-font action_button_outlined" />
                            <CustomButton fieldlabel="Save" variant="contained" className="text-font action_button" />
                          </CardActions>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Grid>
        </Grid>
      </Box>
    </MyDiv>
  )

  return (
    <CustomDrawer
      className="sidebar"
      anchor="left"
      open={props.openCustomSidebar}
      onClose={() => props.handleCustomSidebar()}
    >
      {storeDetails('left')}
    </CustomDrawer>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(AddProductSidebar)
