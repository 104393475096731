import styled from 'styled-components'

const MyDiv = styled.div`
.heading_name_box .MuiOutlinedInput-root .MuiInputBase-input {
    font-size: 0.86rem; 
    color: #000;
}

`
export default MyDiv
