import React, {useState} from 'react'
import {Box, Grid, ImageList, ImageListItem, Snackbar} from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import {connect, useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {bindActionCreators} from 'redux'
import {compose} from 'recompose'
import Checkbox from '@mui/material/Checkbox'
import {ActionCreators} from '../../../../redux/actions'
import MyDiv from './productGallery.style'
const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const label = {inputProps: {'aria-label': 'Checkbox demo'}}

function ProductGallery(props) {
  // console.log(props.images, 'shailesh props.images')
  // console.log(props.selected, 'shailesh props.selected')
  // console.log(props.imfuleCloudData, 'shailesh props.imfuleCloudData')
  // props?.selected?.map((selectedData, index) => (
  //   shArray = props.imfuleCloudData.filter((data) => data.source === selectedData)
  // ))
  // let shArray = props.imfuleCloudData.filter((data) => data.type_id === 2)
  // console.log(shArray, 'shailesh shailesh shailesh shailesh')
  // {var x = props?.imfuleCloudData.filter((cloudDataFilter) => (cloudDataFilter.type_id === 2))}
  // if ((selectedItem) && (selectedItem === cloudElement.source)) {
  //cloudArray[] = imfuleCloudData
  // console.log(selectedItem, 'shailesh singh. hell.!!!')
  // }
  // })
  // }
  // })
  // }

  // let imageDataCloud = []
  // imfuleCloudData.forEach((element) => {
  //   if (props?.selected) {
  //     console.log(element, 'shailesh element')
  //     props.selected.map((listSelected) => {
  //       console.log(props.selected, 'shailesh selected prop')
  //       if (listSelected === element.source) {
  //         //imageDataCloud = element
  //       }
  //     })
  //   }
  // })
  const postState = useSelector((state) => state?.postState)
  const postStateIndex = postState?.getPosition
  const [status, setStatus] = useState(false)
  const [message, setMessage] = useState('')
  const handleSelectImage = (data) => {
    // console.log(data, 'shailesh checkbox selected')
    // console.log(postState, 'boolean shailesh postState comes here...')
    const validFileIndex = postState?.addPostData[postStateIndex]?.image?.findIndex(((e) => e === data))
    if (validFileIndex !== -1 && postState?.addPostData[postStateIndex]?.type) {
      props.actions.onChangeSocialMediaAction(postState?.addPostData?.map(
        (shareholder, sidx) => {
          if (postStateIndex !== sidx) {return shareholder} else {
            shareholder?.image?.splice(validFileIndex, 1)
            return {...shareholder, image: shareholder?.image,
            }
          }
        }),
      )
    } else if (validFileIndex === -1 && postState?.addPostData[postStateIndex]?.image.length + 1 <= postState?.addPostData[postStateIndex]?.imageLimit) {
      props.actions.onChangeSocialMediaAction(postState?.addPostData?.map(
        (shareholder, sidx) => {
          if (postStateIndex !== sidx) {return shareholder} else {
            return {...shareholder, image: [].concat(shareholder.image, data),
            }
          }
        }),
      )
    } else if (postState?.addPostData[postStateIndex]?.type && postState?.addPostData[postStateIndex]?.image.length + 1 >= postState?.addPostData[postStateIndex]?.imageLimit) {
      setStatus(true)
      setMessage(`You cannot select more than ${postState?.addPostData[postStateIndex]?.imageLimit} image.`)
    }
  }
  const handleClose = () => {
    setMessage('')
    setStatus(false)
  }
  return (
    <MyDiv>
      <Box sx={{flexGrow: 1}} mt={3}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <ImageList cols={3} gap={8} className="products_image_list">
              {props?.selected && props.selected.map((item, index) => {
                let shArray = props.imfuleCloudData.filter((data) => data.source === item)
                let [shArrayElement] = shArray
                //console.log(props?.selected, 'boolean boolean hello shailesh sir....shArray')
                if (item === undefined) {
                  return false
                } else {
                  return (<ImageListItem key={index}>
                    {shArrayElement?.media_type === 'Video' ?
                      (
                        <video
                          src={`${item}?w=248&fit=crop&auto=format`}
                          srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                          alt={item}
                          loading="lazy"
                          className="post_images"
                        />
                      ) :
                      (
                        <img
                          src={`${item}?w=248&fit=crop&auto=format`}
                          srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                          alt={item}
                          loading="lazy"
                          className="post_images"
                        />
                      )}
                    <Checkbox {...label} checked={item}
                      onChange={() => handleSelectImage(item)} className="image_check"
                    />
                  </ImageListItem>)
                }
                // if (item === undefined) {
                //   return false
                // } else {
                //   return (<ImageListItem key={index}>
                //     {item.media_type === 'Video' &&
                //     <video
                //       src={`${item}?w=248&fit=crop&auto=format`}
                //       srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                //       alt={item}
                //       loading="lazy"
                //       className="post_images"
                //     />
                //     }
                //     <img
                //       src={`${item}?w=248&fit=crop&auto=format`}
                //       srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                //       alt={item}
                //       loading="lazy"
                //       className="post_images"
                //     />
                //     <Checkbox {...label} checked={item}
                //       onChange={() => handleSelectImage(item)} className="image_check"
                //     />
                //   </ImageListItem>)
                // }

              })}
            </ImageList>
          </Grid>
          <Snackbar open={status} autoHideDuration={2500} onClose={handleClose} >
            <Alert className="redirection_msg_yellow text-font" onClose={handleClose} severity="info" sx={{width: '100%'}}>
              {message}
            </Alert>
          </Snackbar>
        </Grid>
      </Box>
    </MyDiv>
  )
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(ProductGallery)
