/* eslint-disable no-unused-vars */
import React, {memo} from 'react'
import {Box, ImageList, ImageListItem, Grid, Checkbox, Typography} from '@mui/material'
import {MessageBox} from '../../../../../../../../components'
import MyDiv from './cloudUpload.style'

const CloudUpload = memo((props) => {
  const label = {inputProps: {'aria-label': 'Checkbox demo'}}
  return (
    <>
      {console.log(props.imfuleCloudData, 'boolean prop.imfuleCloudData...')}
      {/* {props?.imfuleCloudData.length > 0 &&
        <>
          {props?.imfuleCloudData && props.imfuleCloudData.map((item) => {
            return (<ImageListItem key={item.id}>
              {item.media_type === 'Collage' &&
              <img
                src={`${item.source}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt={item}
                loading="lazy"
                className="post_images"
              />
              }
              <Checkbox {...label} checked={props?.selected?.includes(item?.source)}
                onChange={() => props.handleSelectImage(item?.source)} className="image_check"
              />
            </ImageListItem>)
          })}
        </>
      } */}
      {/* {props?.imfuleCloudData && props.imfuleCloudData.filter((data) => data.media_type === 'Image' || data.media_type === 'Video').map((item, index) => ( */}
      {props?.imfuleCloudData && props.imfuleCloudData.filter((data) => data.media_type === 'Image').map((item, index) => (
        <ImageListItem key={index}>
          {item.media_type === 'Image' &&
          <img
            src={`${item.source}?w=164&h=164&fit=crop&auto=format`}
            srcSet={`${item.source}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            alt={item}
            loading="lazy"
            className="post_images"
          />
          }
          {/* shailesh comment the item.media_type and upperside .filter(=== 'Image') */}
          {/*item.media_type === 'Video' &&
          <video
            src={`${item.source}?w=164&h=164&fit=crop&auto=format`}
            srcSet={`${item.source}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            alt={item}
            loading="lazy"
            className="post_images"
            autoPlay
            loop
          />
        */}
          <Checkbox {...label} checked={props?.selected?.includes(item?.source)}
            onChange={() => props.handleSelectImage(item?.source)} className="image_check"
          />
        </ImageListItem>
      ))}
      {props?.imfuleCloudData.length === 0 && props.emptyBox &&
        <MessageBox messageTitle="No Data Found !!" disableBtn={false} />
      }
    </>
  )
})
export default CloudUpload
