import React, {Suspense} from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import './index.css'
import App from './views/App/App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import store from './redux/store'
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Suspense>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Suspense>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
)
serviceWorkerRegistration.register()
reportWebVitals()
