import React from 'react'
import {ImageListItem, Checkbox, Box, IconButton} from '@mui/material'
import {useSelector} from 'react-redux'
import {Delete} from '@mui/icons-material'
import {MessageBox, CustomButton} from '../../../../components'
import DeleteDialog from '../DeleteDialog'

export default function CloudVideos(props) {
  const label = {inputProps: {'aria-label': 'Checkbox demo'}}
  const mediaState = useSelector((state) => state.mediaState)

  return (
    <>
      {/* {console.log(props?.imfuleCloudData, 'boolean .....props.imfuleCloudData')} */}
      {props?.imfuleCloudData.length > 0 &&
      <>
        {props?.imfuleCloudData && props.imfuleCloudData.filter((data) => data.media_type === 'Video').map((item, index) => (
          <ImageListItem key={index}>
            <video
              src={`${item.source}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${item.source}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              className="post_images"
              autoPlay
              muted loop
            />
            <IconButton className="dlt_icn" onClick={() => props.handleDeleteCloudMedia(item)}><Delete /></IconButton>
            <Checkbox {...label}
              onChange={props.handleCheck(item)}
              checked={props.getCheckedStatus(item)}
              className="image_checkList"
            />
          </ImageListItem>
        ))}
      </>
      }
      {props?.imfuleCloudData.length === 0 &&
        <MessageBox messageTitle="No Data Found !!" disableBtn={false} />
      }
      {(mediaState?.getMediaList?.data?.payload?.pagination?.current_page !== mediaState?.getMediaList?.data?.payload?.pagination?.total_pages) &&
      <Box mt={3} className="load_btn">
        <CustomButton fieldlabel="Load more" variant="contained" onClick={() => props.handleLoadMoreCloud()} className="text-font action_button" />
      </Box>
      }
      <DeleteDialog setOpen={props.setOpen} handleDeleteImage={props.handleDeleteImage} open={props.open} handleDeleteCloudMedia={props.handleDeleteCloudMedia} />
    </>
  )
}
