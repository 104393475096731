/* eslint-disable no-unused-vars */
import React, {useEffect, useRef, useState} from 'react'
import {Autocomplete, Box, CardActions, CardContent, Divider, Drawer, Grid, IconButton, TextField, Typography} from '@mui/material'
import {Close as CloseIcon, Upload} from '@mui/icons-material'
// import SimpleReactValidator from 'simple-react-validator'
import {withRouter, useHistory} from 'react-router-dom'
import {compose} from 'recompose'
import {connect, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'
import {ActionCreators} from '../../redux/actions'
import {CustomButton, CustomTextBox} from '../Inputs'
import {Snackbar, Loader} from '../'
import MyDiv from './addCustomStoreSidebar.style'

function AddCustomStoreSidebar(props) {
  const customStoreState = useSelector((state) => state?.customStoreState)

  const storeDetails = (anchor) => (
    <MyDiv>
      <Box className="sidebar-inner">
        <Grid container>
          <Grid item md={12} xs={12}>
            <CardContent className="content-body">
              <Grid container alignItems="center" className="edit_grid">
                <Grid item xs={8} md={6} sm={6}>
                  <Typography variant="h6" color="#000" fontSize="1.3rem" fontWeight="600" className="text-font" >
                    Add Store Details
                  </Typography>
                </Grid>
                <Grid item xs={0} md={6} sm={6} display={{xs: 'none', lg: 'block', sm: 'block'}}>
                  <CardActions className="set-right">
                    <CustomButton
                      fieldlabel="Cancel"
                      variant="outlined"
                      onClick={() => props.closeCustomSidebar()}
                      className="text-font action_button_outlined"
                    />
                    <CustomButton
                      fieldlabel="Save"
                      variant="contained"
                      className="text-font action_button"
                      onClick={props.handleAddStoreCustom}
                    />
                  </CardActions>
                </Grid>

                <Grid item xs={4} display={{xs: 'block', lg: 'none', sm: 'none'}}>
                  <CardActions className="mobile_icon">
                    <IconButton onClick={() => props.handleCustomSidebar()} color="primary">
                      <CloseIcon />
                    </IconButton>
                  </CardActions>
                </Grid>
              </Grid>
              <Divider />
              <Box sx={{width: '100%'}} mt={4}>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    <Grid container>
                      <form encType="multipart/form-data" >
                        <Grid item xs={12}>
                          <Box mt={2}>
                            <Typography variant="h6" color="text.primary" fontSize="0.9rem" className="text-font" >
                              Store Name
                            </Typography>
                            <CustomTextBox
                              type="text"
                              name="storeName"
                              value={props.inputValues.storeName}
                              //THIS STORE NAME IS PASSED AS A PROP/TYPE IN ONCHANGEINPUT FUNCTION
                              onChange={props.onChangeInput('storeName')}
                              onBlur={() => props.simpleValidator.current.showMessageFor('storeName')}
                              error={customStoreState?.addCustomStore?.error?.errors?.storeName ||
                              props.simpleValidator.current.message('Store Name', props.inputValues.storeName, 'required')}
                              helperText={customStoreState?.addCustomStore?.error?.errors?.name ||
                              props.simpleValidator.current.message('Store Name', props.inputValues.storeName, 'required')}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant="h6" color="text.primary" fontSize="0.9rem" className="text-font" >
                              Store Url
                            </Typography>
                            <CustomTextBox
                              type="text"
                              name="storeUrl"
                              value={props.inputValues.storeUrl}
                              onChange={props.onChangeInput('storeUrl')}
                              onBlur={() => props.simpleValidator.current.showMessageFor('storeUrl')}
                              error={customStoreState?.addCustomStore?.error?.errors?.storeUrl ||
                              props.simpleValidator.current.message('Store Url', props.inputValues.storeUrl, 'required')}
                              helperText={customStoreState?.addCustomStore?.error?.errors?.storeUrl ||
                              props.simpleValidator.current.message('Store Url', props.inputValues.storeUrl, 'required')}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant="h6" color="text.primary" fontSize="0.9rem" className="text-font" >
                              Time Zone
                            </Typography>
                            <Autocomplete
                              className="timezone_box"
                              fullWidth
                              options={props.timeZoneData}
                              isOptionEqualToValue={(option, value) => option?.id === value?.id}
                              onChange={(e, val) => props.handleTimeZoneChange(val)}
                              getOptionLabel={(option) => option.timezone}
                              renderInput={(params) => <TextField {...params} size="small" />}
                            />
                          </Box>
                          {customStoreState?.addCustomStore?.error?.errors?.storeTimezone ||
                          props.simpleValidator.current.message('Time Zone', props.timeZone, 'required')}
                        </Grid>
                        <Grid item xs={12}>
                          <Box mt={2}>
                            <Typography variant="h6" color="text.primary" fontSize="0.9rem" className="text-font" >
                              Import Products (CSV or Excel)
                            </Typography>
                            <Box className="import_box">
                              <CustomButton
                                onClick={props.showOpenFileDialog}
                                fieldlabel="Import"
                                className="text-font action_button btn_color"
                                startIcon={<Upload />}
                              />
                              <input
                                ref={props.imageRef}
                                type="file"
                                style={{display: 'none'}}
                                name="storeImport"
                                // onChange={props.handleChangeFile}
                                onChange={props.handleFileUpload}
                                accept=".xls, .xlsx, .csv"
                              />
                              <Typography variant="h6" color="text.primary" fontSize="0.9rem" className="text-font" >
                                {props.importFileCustom?.name}
                              </Typography>
                            </Box>
                            {customStoreState?.addCustomStore?.error?.errors?.storeImport ||
                            props.simpleValidator.current.message('Import Products', props.storeImport, 'required')}
                            {customStoreState?.addCustomStore?.error?.meta?.status === 'failure' && customStoreState?.addCustomStore?.error?.meta?.message}
                          </Box>
                        </Grid>
                      </form>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Grid>
        </Grid>
      </Box>
    </MyDiv>
  )

  return (
    <Drawer
      className="sidebar"
      anchor="left"
      open={props.openCustomSidebar}
      onClose={() => props.handleCustomSidebar()}
    >
      {storeDetails('left')}
    </Drawer>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(AddCustomStoreSidebar)
