import React, {useEffect, useState} from 'react'
import {Grid, Box, Dialog, DialogActions, DialogContent, DialogContentText, Slide, Typography, IconButton, Divider} from '@mui/material'
import {Close as CloseIcon} from '@mui/icons-material'
import {connect, useSelector} from 'react-redux'
import {withRouter, useHistory} from 'react-router-dom'
import {bindActionCreators} from 'redux'
import {compose} from 'recompose'
import {ActionCreators} from '../../redux/actions'
import * as routesNames from '../../constants/routes'
import {CustomButton, CustomTextBox, Loader, Snackbar} from '..'
import MyDiv from './addCustomStore.style'
const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

const AddCustomStore = (props) => {

  const router = useHistory()
  const authState = useSelector((state) => state.authState)
  const [values, setValues] = useState({})
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('success')

  useEffect(() => {
    if (authState?.signUp?.data?.payload) {
      setMessage(authState.signUp.data.meta?.message)
      setStatus('success')
      props.handleOpenAddCustomStore()
      setValues({})
      router.push(routesNames.VERIFY_OTP, {type: 'register', email: values.email})
    } else if (authState?.signUp?.error?.meta) {
      setMessage(authState.signUp.error.meta?.message)
      setStatus('error')
    }
  }, [authState?.signUp])

  const onChangeInput = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    })
  }

  const handleUserSignUp = (e) => {
    props.actions.signUpAction(values)
  }

  const handleSnackBarClose = () => {
    setTimeout(() => {
      setMessage('')
      setStatus('')
    }, 2500)
  }

  console.log(authState?.signUp?.data?.meta?.status, 'authStateauthState')

  const popupStore = () => (
    <MyDiv>
      {authState?.signUp?.loading &&
      <div>
        <Loader />
      </div>
      }
      <DialogContent className="no-scroll">
        <DialogContentText id="alert-dialog-slide-description" variant="body1"
          color="#000"
          textAlign="center"
          fontSize="1.3rem"
          fontFamily="Poppins"
          fontWeight="600"
          paddingBottom={'15px'}
        >
          Sign Up
          <IconButton onClick={() => props.handleOpenAddCustomStore()} className="close_listing">
            <CloseIcon />
          </IconButton>
        </DialogContentText>
        <Divider />
        <Box mt={1} mb={1}>
          <Typography variant="h6"
            color="text.primary"
            fontSize="0.9rem"
            className="text-font"
          >
            Please signup first, then you will add your custom store
          </Typography>
        </Box>
        <Box sx={{width: '100%'}}>
          <Grid container>
            <Grid item md={12} xs={12}>
              <Grid container >
                <Grid item md={12} xs={12}>
                  <Box mt={2}>
                    <Typography
                      variant="h6"
                      color="text.primary"
                      fontSize="0.9rem"
                      className="text-font"
                      align="left"
                    >
                      Store Name
                    </Typography>
                    <CustomTextBox
                      type="text"
                      name="name"
                      value={values.name ?? ''}
                      onChange={onChangeInput('name')}
                      error={authState?.signUp?.error?.errors?.name?.[0]}
                    />
                  </Box>
                  <Box mt={2}>
                    <Typography
                      variant="h6"
                      color="text.primary"
                      fontSize="0.9rem"
                      className="text-font"
                      align="left"
                    >
                      Email
                    </Typography>
                    <CustomTextBox
                      type="text"
                      name="email"
                      value={values.email ?? ''}
                      onChange={onChangeInput('email')}
                      error={authState?.signUp?.error?.errors?.email?.[0]}
                    />
                  </Box>
                  <Box mt={2}>
                    <Typography
                      variant="h6"
                      color="text.primary"
                      fontSize="0.9rem"
                      className="text-font"
                      align="left"
                    >
                      Password
                    </Typography>
                    <CustomTextBox
                      type="password"
                      name="password"
                      value={values.password ?? ''}
                      onChange={onChangeInput('password')}
                      error={authState?.signUp?.error?.errors?.password?.[0]}
                    />
                  </Box>
                  <Box mt={2}>
                    <Typography
                      variant="h6"
                      color="text.primary"
                      fontSize="0.9rem"
                      className="text-font"
                      align="left"
                    >
                      Confirm Password
                    </Typography>
                    <CustomTextBox
                      type="password"
                      name="passwordConfirmation"
                      value={values.passwordConfirmation ?? ''}
                      onChange={onChangeInput('passwordConfirmation')}
                      error={authState?.signUp?.error?.errors?.password_confirmation?.[0]}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions className="custom_dialog_action">
        <CustomButton fieldlabel="Cancel" onClick={() => props.handleOpenAddCustomStore()} variant="outlined" className="text-font action_button_outlined" />
        <CustomButton fieldlabel="Submit" variant="contained" onClick={handleUserSignUp} className="text-font action_button" />
      </DialogActions>
      <Snackbar
        message={message}
        severity={status}
        duration={2500}
        open={message.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
  return (
    <Dialog
      popupbox="openStore"
      open={props.openPopup}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      onClose={() => props.handleOpenAddCustomStore()}
      aria-describedby="alert-dialog-slide-description"
    >
      {popupStore('openStore')}
    </Dialog>
  )
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(AddCustomStore)
