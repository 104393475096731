/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-undef */
import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Avatar from '@mui/material/Avatar'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
// import ReactImageGrid from '@cordelia273/react-image-grid'
// import Slider from 'react-slick'
import MyDiv from './instagram.style'
// import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css'


function stringAvatar(name) {
  return {
    sx: {
      bgcolor: '#000',
    },
    children: `${name.split(' ')[0][0]}`,
  }
}

export default function Instagram(props) {
  // const [currentSlide, setCurrentSlide] = useState(0)
  console.log(props.socialMediaData.image, 'shailesh singh carosel for instagram')
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 100,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   accessibility: true,
  // adaptiveHeight: true,
  // afterChange: (current) => setCurrentSlide(current),
  // }
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: {max: 4000, min: 3000},
      items: 1,
    },
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: 1,
    },
    tablet: {
      breakpoint: {max: 1024, min: 464},
      items: 1,
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: 1,
    },
  }
  return (
    <MyDiv>
      <Box>
        <Card className={`preview_box ${props.device}`}>
          <Grid container columnSpacing={{xs: 1, sm: 2, md: 3}} className="card_header">
            <Grid item xs={1} md={1} sm={1}>
              {props?.socialMediaData?.page_name &&
              <Avatar className="custom-avatar" {...stringAvatar(props?.socialMediaData?.page_name)} />
              }
            </Grid>
            <Grid item xs={10} md={10} sm={10} >
              <Typography gutterBottom variant="h5" component="div" className="text-font page_title">
                {props?.socialMediaData?.page_name}
              </Typography>
            </Grid>
          </Grid>
          <CardContent className="card_middle">
            <Typography variant="body2"
              className="text-font product_description"
            >
              <p id="p_wrap">{props?.socialMediaData?.description}</p>
            </Typography>
          </CardContent>
          <CardMedia>
            <div className="product_images">
              {/* <img src={props?.socialMediaData?.image[1]} alt="user" loading="lazy" /> */}
              {/* <Slider {...settings}>
                {
                  props?.socialMediaData?.image && props?.socialMediaData?.image.filter((data) => (data !== undefined)).map((caroselImage, index) => (
                    <div key={{index}}>
                      <img src={caroselImage} loading="lazy" />
                    </div>
                  ))
                }
              </Slider> */}
              <Carousel responsive={responsive}>
                {
                  props?.socialMediaData?.image && props?.socialMediaData?.image.filter((data) => (data !== undefined)).map((caroselImage, index) => (
                    <div key={{index}}>
                      <img src={caroselImage} loading="lazy" />
                    </div>
                  ))
                }
              </Carousel>
              {/* <React images={props?.socialMediaData?.image} /> */}
              {/* <ReactImageGrid
                images={props?.socialMediaData?.image.slice(1)}
              /> */}
            </div>
          </CardMedia>

        </Card>
      </Box>
    </MyDiv>
  )
}
