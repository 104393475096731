export const ADD_POST_DATA = 'ADD_POST_DATA'
export const UPDATE_POST_DATA = 'UPDATE_POST_DATA'
export const CHANGE_DATA = 'CHANGE_SOCIAL_MEDIA_DATA'
export const REMOVE_DATA = 'REMOVE_DATA'
export const GET_POST_DATA_INDEX_DATA = 'GET_POST_DATA_INDEX_DATA'
export const CLEANUP_ADD_POST_DATA = 'CLEANUP_ADD_POST_DATA'
export const CLEANUP_POSTED_LIST_DATA = 'CLEANUP_POSTED_LIST_DATA'

export const GET_POSTED_LIST_DATA_REQUEST = 'GET_POSTED_LIST_DATA_REQUEST'
export const GET_POSTED_LIST_DATA_FAILURE = 'GET_POSTED_LIST_DATA_FAILURE'
export const GET_POSTED_LIST_DATA_SUCCESS = 'GET_POSTED_LIST_DATA_SUCCESS'

export const GET_CALENDAR_POSTED_LIST_DATA_REQUEST = 'GET_CALENDAR_POSTED_LIST_DATA_REQUEST'
export const GET_CALENDAR_POSTED_LIST_DATA_FAILURE = 'GET_CALENDAR_POSTED_LIST_DATA_FAILURE'
export const GET_CALENDAR_POSTED_LIST_DATA_SUCCESS = 'GET_CALENDAR_POSTED_LIST_DATA_SUCCESS'

export const GET_POSTED_LIST_LOAD_MORE_DATA_REQUEST = 'GET_POSTED_LIST_LOAD_MORE_DATA_REQUEST'
export const GET_POSTED_LIST_LOAD_MORE_DATA_FAILURE = 'GET_POSTED_LIST_LOAD_MORE_DATA_FAILURE'
export const GET_POSTED_LIST_LOAD_MORE_DATA_SUCCESS = 'GET_POSTED_LIST_LOAD_MORE_DATA_SUCCESS'

export const DELETE_POSTED_LIST_REQUEST = 'DELETE_POSTED_LIST_REQUEST'
export const DELETE_POSTED_LIST_FAILURE = 'DELETE_POSTED_LIST_FAILURE'
export const DELETE_POSTED_LIST_SUCCESS = 'DELETE_POSTED_LIST_SUCCESS'

export const DELETE_POSTED_ITEM_REQUEST = 'DELETE_POSTED_ITEM_REQUEST'
export const DELETE_POSTED_ITEM_FAILURE = 'DELETE_POSTED_ITEM_FAILURE'
export const DELETE_POSTED_ITEM_SUCCESS = 'DELETE_POSTED_ITEM_SUCCESS'

export const GET_SPECIFIC_POSTED_DATA_REQUEST = 'GET_SPECIFIC_POSTED_DATA_REQUEST'
export const GET_SPECIFIC_POSTED_DATA_FAILURE = 'GET_SPECIFIC_POSTED_DATA_FAILURE'
export const GET_SPECIFIC_POSTED_DATA_SUCCESS = 'GET_SPECIFIC_POSTED_DATA_SUCCESS'

export const UPDATE_POST_DATA_REQUEST = 'UPDATE_POST_DATA_REQUEST'
export const UPDATE_POST_DATA_FAILURE = 'UPDATE_POST_DATA_FAILURE'
export const UPDATE_POST_DATA_SUCCESS = 'UPDATE_POST_DATA_SUCCESS'

export const GET_ARCHIVE_LIST_DATA_REQUEST = 'GET_ARCHIVE_LIST_DATA_REQUEST'
export const GET_ARCHIVE_LIST_DATA_FAILURE = 'GET_ARCHIVE_LIST_DATA_FAILURE'
export const GET_ARCHIVE_LIST_DATA_SUCCESS = 'GET_ARCHIVE_LIST_DATA_SUCCESS'

export const GET_ARCHIVE_LIST_LOAD_MORE_DATA_REQUEST = 'GET_ARCHIVE_LIST_LOAD_MORE_DATA_REQUEST'
export const GET_ARCHIVE_LIST_LOAD_MORE_DATA_FAILURE = 'GET_ARCHIVE_LIST_LOAD_MORE_DATA_FAILURE'
export const GET_ARCHIVE_LIST_LOAD_MORE_DATA_SUCCESS = 'GET_ARCHIVE_LIST_LOAD_MORE_DATA_SUCCESS'

export const GET_SPECIFIC_ARCHIVE_DATA_REQUEST = 'GET_SPECIFIC_ARCHIVE_DATA_REQUEST'
export const GET_SPECIFIC_ARCHIVE_DATA_FAILURE = 'GET_SPECIFIC_ARCHIVE_DATA_FAILURE'
export const GET_SPECIFIC_ARCHIVE_DATA_SUCCESS = 'GET_SPECIFIC_ARCHIVE_DATA_SUCCESS'

export const FILTERED_POSTED_LIST_SUCCESS = 'FILTERED_POSTED_LIST_SUCCESS'

export const FILTERED_POSTED_ITEM_SUCCESS = 'FILTERED_POSTED_ITEM_SUCCESS'

export const SET_IMAGE_LIMIT_FACEBOOK_SOCIAL_MEDIA = 'SET_IMAGE_LIMIT_FACEBOOK_SOCIAL_MEDIA'

