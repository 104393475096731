import apiInstance from '../config/api/axios'

export const addCustomProduct = async(data) => {
  let config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'accept': 'application/json',
    },
  }
  const response = await apiInstance.post('add-custom-product', data, config)
  return response
}
