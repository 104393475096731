import styled from 'styled-components'

const MyDiv = styled.div`
.scheduled_menu{
    color: rgb(0, 0, 0);
    font-size: 0.9rem;
    font-family: Poppins;
    font-weight: 500;
    text-transform: capitalize;
}
.action_icon{
    color: #283c86;  
}

`
export default MyDiv
