import * as customStoreServices from '../../services/customStoreServices'
import * as actionTypes from './actionsType'
import {getStoreAction} from './userActions'

const addCustomStoreRequest = () => {
  return {
    type: actionTypes.USER_ADD_CUSTOM_STORE_REQUEST,
  }
}
const addCustomStoreFailure = (error) => {
  return {
    type: actionTypes.USER_ADD_CUSTOM_STORE_FAILURE,
    payload: error,
  }
}
const addCustomStoreSuccess = (data) => {
  return {
    type: actionTypes.USER_ADD_CUSTOM_STORE_SUCCESS,
    payload: data,
  }
}

export const addCustomStoreAction = (data) =>
  async(dispatch) => {
    dispatch(addCustomStoreRequest())
    try {
      const responseData = await customStoreServices.addCustomStore(data)
      if (responseData?.meta?.status?.toUpperCase() === 'SUCCESS') {
        dispatch(addCustomStoreSuccess(responseData))
        dispatch(getStoreAction())
      } else {
        dispatch(addCustomStoreFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(addCustomStoreFailure(error?.response?.data))
    }
  }

const cleanUpCustomStoreStateRequest = () => {
  return {
    type: actionTypes.CLEAN_UP_CUSTOM_STORE_STATE_REQUEST,
  }
}

export const cleanUpCustomStoreState = () =>
  async(dispatch) => {
    dispatch(cleanUpCustomStoreStateRequest())
  }
