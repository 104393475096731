import styled from 'styled-components'

const MyDiv = styled.div`
.shopify-iconbutton{
    width:auto;
}
.btn-shopify{
    background-color: #dfecc7;
    padding:0px 10px;
}
.btn-prestashop{
    background-color: #c8e7ef;
    padding:0px 10px;
}
.btn-prestashop:hover{
    background-color: #c8e7ef;
}
.btn-shopify:hover{
    background-color: #dfecc7;
}
.btn-custom{
    background-color: #ddd;
    padding:0px 10px;
}
.btn-custom:hover{
    background-color: #ddd;
}
.btn-woo{
    background-color: #e7d9e5;
    padding:0px 10px;
}
.btn-woo:hover{
    background-color: #e7d9e5;
}
.btn-magento{
    background-color: #fdd1c4;
    padding:0px 10px;
}
.btn-magento:hover{
    background-color: #fdd1c4;
}
.shopify-text{
    font-family: 'Poppins',sans-serif;
    text-transform: capitalize;
    color:#000;
    margin-right: 0rem;
    font-size: 0.8rem;
  
}
.top_box{
    margin-top: 16px;
    display: flex;
    justify-content: center;
    column-gap: 15px;
    row-gap: 15px;
    flex-wrap: wrap;
}
.btn-custom svg{
    width: 30px;
    height: 30px;
    padding: 5px 0px;
    fill: #000;
}
`
export default MyDiv
