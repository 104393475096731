import React from 'react'
const OverviewCard = React.lazy(() => import('./OverviewCard'))
const SubscriptionCard = React.lazy(() => import('./SubscriptionCard'))
const NetworkSettingsCard = React.lazy(() => import('./NetworkSettingsCard'))
const UserDetails = React.lazy(() => import('./UserDetailsCard'))
const EditProfile = React.lazy(() => import('./EditProfile'))
const ChangePassword = React.lazy(() => import('./ChangePassword'))
const SetPassword = React.lazy(() => import('./SetPassword'))
const StoreCard = React.lazy(() => import('./StoreCard'))
const CompanyCard = React.lazy(() => import('./CompanyCard'))
const PlanListing = React.lazy(() => import('./PlanListing'))


export {
  OverviewCard,
  SubscriptionCard,
  UserDetails,
  NetworkSettingsCard,
  EditProfile,
  ChangePassword,
  SetPassword,
  StoreCard,
  PlanListing,
  CompanyCard,
}

