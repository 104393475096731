import * as actionTypes from '../actions/actionsType'

const INITIAL_STATE = {
  addCustomStore: {
    data: null,
    loading: false,
    error: null,
  },
}

const customStoreReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.USER_ADD_CUSTOM_STORE_REQUEST:
      return {
        ...state,
        addCustomStore: {
          ...state.addCustomStore,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.USER_ADD_CUSTOM_STORE_SUCCESS:
      return {
        ...state,
        addCustomStore: {
          ...state.addCustomStore,
          data: action.payload,
          loading: null,
          error: null,
        },
      }
    case actionTypes.USER_ADD_CUSTOM_STORE_FAILURE:
      return {
        ...state,
        addCustomStore: {
          ...state.addCustomStore,
          data: null,
          loading: null,
          error: action.payload,
        },
      }
    case actionTypes.CLEAN_UP_CUSTOM_STORE_STATE_REQUEST:
      return {
        addCustomStore: {
          ...state,
          addCustomStore: {
            ...state.addCustomStore,
            data: null,
            loading: false,
            error: null,
          },
        },
      }
    default:
      return state
  }
}

export default customStoreReducers
