import styled from 'styled-components'

const MyDiv = styled.div`
background-color: #283c86;
color: white;
text-align: center;
min-height: 50px;
line-height: 50px;
// position: fixed;
 width: 100%;
 bottom: 0;
 z-index: 1;
`
export default MyDiv
