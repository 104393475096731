import React from 'react'
import {Box, Grid, Skeleton} from '@mui/material'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {bindActionCreators} from 'redux'
import {compose} from 'recompose'
import {ActionCreators} from '../../../../redux/actions'
import MyDiv from './promotionGallery.style'

function PromotionGallery(props) {
  // console.log(props.image, 'shailesh singh image comes here')
  // console.log(props.video, 'shailesh singh video comes here')
  // console.log(props.selected, 'shailesh in side the promotionGallery')
  return (
    <>
      {typeof props.video !== undefined && props.video ?
        <MyDiv>
          <Box mt={3}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <video width="480" controls>
                  <source src={props.video} type="video/mp4" />
                </video>
              </Grid>
            </Grid>
          </Box>
        </MyDiv>
        :
        <MyDiv>
          <Box mt={3}>
            <Grid container>
              <Grid item xs={12} md={12}>
                {props?.selected?.length > 0 ? <img className="imageCollage" src={props.selected} loading="lazy" /> : <Skeleton variant="rectangular" animation="wave" className="collage_skeleton" />
                }
              </Grid>
            </Grid>
          </Box>
        </MyDiv>
      }
    </>
  )
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(PromotionGallery)
