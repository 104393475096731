import React from 'react'
import {OverviewCard, SubscriptionCard, NetworkSettingsCard, StoreCard, CompanyCard, PlanListing} from './Settings/profile/components'
// const Calender = React.lazy(() => import('./Calender'))
import Calender from './Calender'
const Redirecting = React.lazy(() => import('../components/Redirecting'))
const Login = React.lazy(() => import('./Login'))
const Signup = React.lazy(() => import('./Signup'))
const Dashboard = React.lazy(() => import('./Dashboard'))
const Profile = React.lazy(() => import('./Settings/profile'))
const ForgotPassword = React.lazy(() => import('./ForgotPassword'))
const UpdatePassword = React.lazy(() => import('./UpdatePassword'))
const VerifyOTP = React.lazy(() => import('./VerifyOTP'))
const ShopifyResponse = React.lazy(() => import('./ShopifyResponse'))
const ShopifyInstall = React.lazy(() => import('./ShopifyInstall'))
const FacebookResponse = React.lazy(() => import('./FacebookResponse'))
const ProductsListing = React.lazy(() => import('./ProductsListing'))
const ForgotChangePassword = React.lazy(() => import('./ForgotChangePassword'))
const Scheduled = React.lazy(() => import('./Scheduled'))
const Archive = React.lazy(() => import('./Archive'))
const PinterestResponse = React.lazy(() => import('./PinterestResponse'))
const TwitterResponse = React.lazy(() => import('./TwitterResponse'))
const Promotion = React.lazy(() => import('./Promotion'))
const LinkedinResponse = React.lazy(() => import('./LinkedinResponse'))
export {
  Login,
  Signup,
  Profile,
  ForgotPassword,
  UpdatePassword,
  VerifyOTP,
  Calender,
  Dashboard,
  FacebookResponse,
  ShopifyResponse,
  ShopifyInstall,
  ProductsListing,
  ForgotChangePassword,
  Scheduled,
  Archive,
  PinterestResponse,
  TwitterResponse,
  OverviewCard,
  SubscriptionCard,
  NetworkSettingsCard,
  StoreCard,
  CompanyCard,
  //Redirecting,
  Redirecting,
  Promotion,
  LinkedinResponse,
  PlanListing,
}

