import React, {Suspense} from 'react'
import {
  Switch, Route, BrowserRouter, Redirect,
} from 'react-router-dom'

import AppLayout from '../../config/layouts'
import {getToken} from '../../utilities/authUtils'
import * as routesNames from '../../constants/routes'
import {
  Redirecting,
} from '..'
import {Loader} from '../../components'
const Profile = React.lazy(() => import('../Settings/profile'))
const Login = React.lazy(() => import('../Login'))
const Calender = React.lazy(() => import('../Calender'))
const Signup = React.lazy(() => import('../Signup'))
const ForgotPassword = React.lazy(() => import('../ForgotPassword'))
const UpdatePassword = React.lazy(() => import('../UpdatePassword'))
const VerifyOTP = React.lazy(() => import('../VerifyOTP'))
const Dashboard = React.lazy(() => import('../Dashboard'))
const ShopifyResponse = React.lazy(() => import('../ShopifyResponse'))
const ShopifyInstall = React.lazy(() => import('../ShopifyInstall'))
const ProductsListing = React.lazy(() => import('../ProductsListing'))
const ForgotChangePassword = React.lazy(() => import('../ForgotChangePassword'))
const FacebookResponse = React.lazy(() => import('../FacebookResponse'))
const PinterestResponse = React.lazy(() => import('../PinterestResponse'))
const TwitterResponse = React.lazy(() => import('../TwitterResponse'))
const LinkedinResponse = React.lazy(() => import('../LinkedinResponse'))
const Scheduled = React.lazy(() => import('../Scheduled'))
const Archive = React.lazy(() => import('../Archive'))
const Promotion = React.lazy(() => import('../Promotion'))

const Routes = () => {
  const PrivateRoute = ({component: Component, ...rest}) => {
    return (<Route
      {...rest}
      render={(props) => getToken('token') ? (
        <Component {...props} />
      ) : (
        <Redirect to={{pathname: routesNames.LOGIN}} />
      )}
    />)
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>

        <Switch>
          <Redirect from="/" exact to={{pathname: routesNames.PROFILE}} />
          <Route exact path={routesNames.LOGIN} component={Login} />
          <Route exact path={routesNames.SIGNUP} component={Signup} />
          <Route exact path={routesNames.FORGOT_PASSWORD} component={ForgotPassword} />
          <Route exact path={routesNames.UPDATE_PASSWORD} component={UpdatePassword} />
          <Route exact path={routesNames.VERIFY_OTP} component={VerifyOTP} />
          <Route exact path={routesNames.SHOPIFY_RESPONSE} component={ShopifyResponse} />
          <Route exact path={routesNames.SHOPIFY_INSTALL} component={ShopifyInstall} />
          <Route exact path={routesNames.FACEBOOK_RESPONSE} component={FacebookResponse} />
          <Route exact path={routesNames.TWITTER_RESPONSE} component={TwitterResponse} />
          <Route exact path={routesNames.PINTEREST_RESPONSE} component={PinterestResponse} />
          <Route exact path={routesNames.LINKEDIN_RESPONSE} component={LinkedinResponse} />
          <Route exact path={routesNames.FORGOT_CHANGE_PASSWORD} component={ForgotChangePassword} />
          <Route exact path={routesNames.REDIRECTING} component={Redirecting} />
          <AppLayout>
            <PrivateRoute exact path={routesNames.DASHBOARD} component={Dashboard} />
            <PrivateRoute path={routesNames.PROFILE} component={Profile} />
            <PrivateRoute exact path={routesNames.CALENDER} component={Calender} />
            <PrivateRoute exact path={routesNames.PRODUCTS_LISTING} component={ProductsListing} />
            <PrivateRoute exact path={routesNames.SCHEDULED} component={Scheduled} />
            <PrivateRoute exact path={routesNames.ARCHIVE} component={Archive} />
            <PrivateRoute exact path={routesNames.PROMOTION} component={Promotion} />
          </AppLayout>
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

export default Routes
