import styled from 'styled-components'

const MyDiv = styled.div`
.close_listing{
    position: absolute;
    right: 0;
    top: 0;
    color:#283c86;
}
.custom_dialog_action{
    padding: 0px 24px 24px;
}

`
export default MyDiv
