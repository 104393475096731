export const GET_STORE_LIST_REQUEST = 'GET_STORE_LIST_REQUEST'
export const GET_STORE_LIST_FAILURE = 'GET_STORE_LIST_FAILURE'
export const GET_STORE_LIST_SUCCESS = 'GET_STORE_LIST_SUCCESS'

export const ADD_STORE_SHOPIFY_REQUEST = 'ADD_STORE_SHOPIFY_REQUEST'
export const ADD_STORE_SHOPIFY_FAILURE = 'ADD_STORE_SHOPIFY_FAILURE'
export const ADD_STORE_SHOPIFY_SUCCESS = 'ADD_STORE_SHOPIFY_SUCCESS'

export const ADD_STORE_SHOPIFY_RESPONSE_REQUEST = 'ADD_STORE_SHOPIFY_RESPONSE_REQUEST'
export const ADD_STORE_SHOPIFY_RESPONSE_FAILURE = 'ADD_STORE_SHOPIFY_RESPONSE_FAILURE'
export const ADD_STORE_SHOPIFY_RESPONSE_SUCCESS = 'ADD_STORE_SHOPIFY_RESPONSE_SUCCESS'

export const ADD_STORE_SHOPIFY_INSTALL_REQUEST = 'ADD_STORE_SHOPIFY_INSTALL_REQUEST'
export const ADD_STORE_SHOPIFY_INSTALL_FAILURE = 'ADD_STORE_SHOPIFY_INSTALL_FAILURE'
export const ADD_STORE_SHOPIFY_INSTALL_SUCCESS = 'ADD_STORE_SHOPIFY_INSTALL_SUCCESS'

export const DELETE_STORE_REQUEST = 'DELETE_STORE_REQUEST'
export const DELETE_STORE_FAILURE = 'DELETE_STORE_FAILURE'
export const DELETE_STORE_SUCCESS = 'DELETE_STORE_SUCCESS'

export const GET_SOCIAL_MEDIA_LIST_REQUEST = 'GET_SOCIAL_MEDIA_LIST_REQUEST'
export const GET_SOCIAL_MEDIA_LIST_FAILURE = 'GET_SOCIAL_MEDIA_LIST_FAILURE'
export const GET_SOCIAL_MEDIA_LIST_SUCCESS = 'GET_SOCIAL_MEDIA_LIST_SUCCESS'

export const ADD_SOCIAL_MEDIA_REQUEST = 'ADD_SOCIAL_MEDIA_REQUEST'
export const ADD_SOCIAL_MEDIA_FAILURE = 'ADD_SOCIAL_MEDIA_FAILURE'
export const ADD_SOCIAL_MEDIA_SUCCESS = 'ADD_SOCIAL_MEDIA_SUCCESS'

export const ADD_SOCIAL_MEDIA_RESPONSE_REQUEST = 'ADD_SOCIAL_MEDIA_RESPONSE_REQUEST'
export const ADD_SOCIAL_MEDIA_RESPONSE_FAILURE = 'ADD_SOCIAL_MEDIA_RESPONSE_FAILURE'
export const ADD_SOCIAL_MEDIA_RESPONSE_SUCCESS = 'ADD_SOCIAL_MEDIA_RESPONSE_SUCCESS'

export const CONNECT_SHOPS_SOCIAL_MEDIA_REQUEST = 'CONNECT_SHOPS_SOCIAL_MEDIA_REQUEST'
export const CONNECT_SHOPS_SOCIAL_MEDIA_FAILURE = 'CONNECT_SHOPS_SOCIAL_MEDIA_FAILURE'
export const CONNECT_SHOPS_SOCIAL_MEDIA_SUCCESS = 'CONNECT_SHOPS_SOCIAL_MEDIA_SUCCESS'

export const DELETE_SOCIAL_MEDIA_REQUEST = 'DELETE_SOCIAL_MEDIA_REQUEST'
export const DELETE_SOCIAL_MEDIA_FAILURE = 'DELETE_SOCIAL_MEDIA_FAILURE'
export const DELETE_SOCIAL_MEDIA_SUCCESS = 'DELETE_SOCIAL_MEDIA_SUCCESS'

export const CHANGE_SOCIAL_MEDIA_STATUS_REQUEST = 'CHANGE_SOCIAL_MEDIA_STATUS_REQUEST'
export const CHANGE_SOCIAL_MEDIA_STATUS_FAILURE = 'CHANGE_SOCIAL_MEDIA_STATUS_FAILURE'
export const CHANGE_SOCIAL_MEDIA_STATUS_SUCCESS = 'CHANGE_SOCIAL_MEDIA_STATUS_SUCCESS'

export const CONNECTED_SOCIAL_MEDIA_WITH_SHOPS_REQUEST = 'CONNECTED_SOCIAL_MEDIA_WITH_SHOPS_REQUEST'
export const CONNECTED_SOCIAL_MEDIA_WITH_SHOPS_FAILURE = 'CONNECTED_SOCIAL_MEDIA_WITH_SHOPS_FAILURE'
export const CONNECTED_SOCIAL_MEDIA_WITH_SHOPS_SUCCESS = 'CONNECTED_SOCIAL_MEDIA_WITH_SHOPS_SUCCESS'

export const CONNECTED_PAGES_WITH_SOCIAL_MEDIA_REQUEST = 'CONNECTED_PAGES_WITH_SOCIAL_MEDIA_REQUEST'
export const CONNECTED_PAGES_WITH_SOCIAL_MEDIA_FAILURE = 'CONNECTED_PAGES_WITH_SOCIAL_MEDIA_FAILURE'
export const CONNECTED_PAGES_WITH_SOCIAL_MEDIA_SUCCESS = 'CONNECTED_PAGES_WITH_SOCIAL_MEDIA_SUCCESS'

export const POST_ON_SOCIAL_MEDIA_REQUEST = 'POST_ON_SOCIAL_MEDIA_REQUEST'
export const POST_ON_SOCIAL_MEDIA_FAILURE = 'POST_ON_SOCIAL_MEDIA_FAILURE'
export const POST_ON_SOCIAL_MEDIA_SUCCESS = 'POST_ON_SOCIAL_MEDIA_SUCCESS'

export const CLEAN_UP_USER_STATE_REQUEST = 'CLEAN_UP_USER_STATE_REQUEST'

export const GET_STEPPER_REQUEST = 'GET_STEPPER_REQUEST'
export const GET_STEPPER_FAILURE = 'GET_STEPPER_FAILURE'
export const GET_STEPPER_SUCCESS = 'GET_STEPPER_SUCCESS'

export const UPDATE_TIMEZONE_REQUEST = 'UPDATE_TIMEZONE_REQUEST'
export const UPDATE_TIMEZONE_FAILURE = 'UPDATE_TIMEZONE_FAILURE'
export const UPDATE_TIMEZONE_SUCCESS = 'UPDATE_TIMEZONE_SUCCESS'

