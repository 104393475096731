import styled from 'styled-components'

const MyDiv = styled.div`
.sidebar-inner{
    width:100%;
    @media(max-width:767px){
            width:100%;
    }
}
.set-right {
    float: right;
    padding: 0;
    @media(max-width:767px){
        padding: 24px 0;
    }
}
.edit_grid{
    padding-bottom:16px;
}

.mobile_icon{
    float: right;
    padding: 0;
    @media(max-width:767px){
        padding: 0px 0;
    }
}
.mobile_icon button{
    @media(max-width:767px){
    color: #283c86;
    }
}

.timezone_box .MuiAutocomplete-input {
    width: 100%!important;
}
.btn_color{
    color: #fff;
}
.import_box{
    display: inline-block;
    margin-bottom: 15px;
    margin-top: 5px;
}
.close_icon button{
    color: #283c86;
}
.uploaded_image{
    width: 100%;
    display: flex;
    align-items: center;
    flex-wap: wrap;
}
.uploaded_image img{
    width: 150px;
}
`
export default MyDiv
