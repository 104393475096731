import {Dialog, DialogActions, DialogContent, DialogContentText} from '@mui/material'
import React from 'react'
import {CustomButton} from '../../../../components'
import MyDiv from './deleteDialog.style'

export default function DeleteDialog(props) {
  return (
    <MyDiv>
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
      >
        <DialogContent>
          <DialogContentText>
            Do you wish to delete the cloud media?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={props.handleDeleteImage} fieldlabel="Yes" />
          <CustomButton onClick={() => props.setOpen(false)} fieldlabel="No" />
        </DialogActions>
      </Dialog>
    </MyDiv>
  )
}
