export const GET_COUNTRY_REQUEST = 'GET_COUNTRY_REQUEST'
export const GET_COUNTRY_FAILURE = 'GET_COUNTRY_FAILURE'
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS'

export const GET_TIMEZONE_REQUEST = 'GET_TIMEZONE_REQUEST'
export const GET_TIMEZONE_FAILURE = 'GET_TIMEZONE_FAILURE'
export const GET_TIMEZONE_SUCCESS = 'GET_TIMEZONE_SUCCESS'

export const GET_SETTING_LIST_REQUEST = 'GET_SETTING_LIST_REQUEST'
export const GET_SETTING_LIST_FAILURE = 'GET_SETTING_LIST_FAILURE'
export const GET_SETTING_LIST_SUCCESS = 'GET_SETTING_LIST_SUCCESS'
