export const PRODUCT_CATEGORIES_REQUEST = 'PRODUCT_CATEGORIES_REQUEST'
export const PRODUCT_CATEGORIES_FAILURE = 'PRODUCT_CATEGORIES_FAILURE'
export const PRODUCT_CATEGORIES_SUCCESS = 'PRODUCT_CATEGORIES_SUCCESS'

export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_FAILURE = 'PRODUCT_LIST_FAILURE'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_FAILURE = 'PRODUCT_DETAILS_FAILURE'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const CLEAN_UP_PRODUCT_DETAILS_REQUEST = 'CLEAN_UP_PRODUCT_DETAILS_REQUEST'

export const IMPORT_PRODUCT_REQUEST = 'IMPORT_PRODUCT_REQUEST'
export const IMPORT_PRODUCT_FAILURE = 'IMPORT_PRODUCT_FAILURE'
export const IMPORT_PRODUCT_SUCCESS = 'IMPORT_PRODUCT_SUCCESS'

export const IMPORT_CATEGORY_REQUEST = 'IMPORT_CATEGORY_REQUEST'
export const IMPORT_CATEGORY_FAILURE = 'IMPORT_CATEGORY_FAILURE'
export const IMPORT_CATEGORY_SUCCESS = 'IMPORT_CATEGORY_SUCCESS'

export const IMPORT_SCRIPT_REQUEST = 'IMPORT_SCRIPT_REQUEST'
export const IMPORT_SCRIPT_FAILURE = 'IMPORT_SCRIPT_FAILURE'
export const IMPORT_SCRIPT_SUCCESS = 'IMPORT_SCRIPT_SUCCESS'

export const CLEAN_UP_PRODUCT_STATE_REQUEST = 'CLEAN_UP_PRODUCT_STATE_REQUEST'
export const CLEAN_UP_PRODUCT_LIST_REQUEST = 'CLEAN_UP_PRODUCT_LIST_REQUEST'

export const PRODUCT_FILTER = 'PRODUCT_FILTER'
