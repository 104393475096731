import * as customProductServices from '../../services/customProductServices'
import * as actionTypes from './actionsType'
import {getProductsAction} from './productActions'

const addCustomProductRequest = () => {
  return {
    type: actionTypes.USER_ADD_CUSTOM_PRODUCT_REQUEST,
  }
}

const addCustomProductFailure = (error) => {
  return {
    type: actionTypes.USER_ADD_CUSTOM_PRODUCT_FAILURE,
    payload: error,
  }
}

const addCustomProductSuccess = (data) => {
  return {
    type: actionTypes.USER_ADD_CUSTOM_PRODUCT_SUCCESS,
    payload: data,
  }
}

export const addCustomProductAction = (data, obj, companyID) =>
  async(dispatch) => {
    dispatch(addCustomProductRequest())
    try {
      const responseData = await customProductServices.addCustomProduct(data)
      if (responseData?.meta?.status?.toUpperCase() === 'SUCCESS') {
        dispatch(addCustomProductSuccess(responseData))
        dispatch(getProductsAction(obj, companyID))
      } else {
        dispatch(addCustomProductFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(addCustomProductFailure(error?.response?.data))
    }
  }

const cleanUpCustomProductStateRequest = () => {
  return {
    type: actionTypes.CLEAN_UP_CUSTOM_PRODUCT_STATE_REQUEST,
  }
}

export const cleanUpCustomProductState = () =>
  async(dispatch) => {
    dispatch(cleanUpCustomProductStateRequest())
  }
