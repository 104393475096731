/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, {useState} from 'react'
import {Chip, Box, Typography, Stack, TextareaAutosize, IconButton} from '@mui/material'
import {connect, useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {bindActionCreators} from 'redux'
import {compose} from 'recompose'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import {EmojiEmotions} from '@material-ui/icons'
import {ActionCreators} from '../../../../redux/actions'
import MyDiv from './richTextEditor.style'

function RichTextEditor(props) {
  const [picker, setPicker] = useState(false)
  const [text, setText] = useState('')
  const postState = useSelector((state) => state?.postState)
  const postStateIndex = postState?.getPosition
  const handleClick = (value) => {
    let valueNew = (postState?.addPostData[postStateIndex]?.description + value)
    let data = valueNew.slice(0, postState?.addPostData[postStateIndex]?.limitDesc)
    if (postState?.addPostData[postStateIndex]?.limitDesc >= (data?.length)) {
      props.actions.onChangeSocialMediaAction(postState?.addPostData?.map(
        (shareholder, sidx) => {
          if (postStateIndex !== sidx) {return shareholder} else {
            return {...shareholder, description: data,
            }
          }
        }),
      )
    }
  }
  const handleChange = (value, text) => {
    let data = value.slice(0, postState?.addPostData[postStateIndex]?.limitDesc)
    if (postState?.addPostData[postStateIndex]?.limitDesc >= data?.length) {
      props.actions.onChangeSocialMediaAction(postState?.addPostData?.map(
        (shareholder, sidx) => {
          if (postStateIndex !== sidx) {return shareholder} else {
            return {...shareholder, description: data,
            }
          }
        }),
      )
    }

  }
  const handleClean = () => {
    props.actions.onChangeSocialMediaAction(postState?.addPostData?.map(
      (shareholder, sidx) => {
        if (postStateIndex !== sidx) {return shareholder} else {
          return {...shareholder, description: '',
          }
        }
      }),
    )
  }


  function handleOnEnter(text) {
    let data = text.slice(0, postState?.addPostData[postStateIndex]?.limitDesc)
    if (postState?.addPostData[postStateIndex]?.limitDesc >= data?.length) {
      props.actions.onChangeSocialMediaAction(postState?.addPostData?.map(
        (shareholder, sidx) => {
          if (postStateIndex !== sidx) {return shareholder} else {
            return {...shareholder, description: data,
            }
          }
        }),
      )
    }
  }

  const handleEmojiSelect = (e) => {
    setPicker(!picker)
    const selectedEmoji = e.native
    setText(text + selectedEmoji)
    handleClick(e.native)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      setText(text.slice(0, -1))
    }
  }
  return (
    <MyDiv>
      <div className="custom_editor">
        <TextareaAutosize
          aria-label="empty textarea"
          placeholder="Description"
          style={{width: '100%', fontFamily: 'Poppins', fontSize: '0.8rem', padding: '5px 13px'}}
          minRows={10}
          onChange={(e) => handleChange(e.target.value)}
          value={`${postState?.addPostData[postStateIndex]?.description}`}
          onKeyDown={handleKeyDown}
        />
        {picker ? <div>
          <Picker data={data} onEmojiSelect={handleEmojiSelect} />
        </div> : null}
      </div>
      <Box>
        <Typography gutterBottom variant="h5" component="div" className="text-font counter_heading" >
          [{postState?.addPostData[postStateIndex]?.description?.length}/{postState?.addPostData[postStateIndex]?.limitDesc}]
        </Typography>
      </Box>
      <Box >
        <Stack spacing={1} sx={{display: 'inline-block'}}>
          <Chip
            label="Title"
            component="a"
            variant="outlined"
            className="add_field text-font"
            clickable
            onClick={() => handleClick(postState?.addPostData[postStateIndex]?.description === '' ? `${props?.productData?.product_name}` : `\n${props?.productData?.product_name}`)}
          />
          <Chip
            label="Description"
            component="a"
            variant="outlined"
            clickable
            className="add_field text-font"
            onClick={() => handleClick(postState?.addPostData[postStateIndex]?.description === '' ? `${props?.productData?.clean_description}` : `\n${props?.productData?.clean_description}`)}
          />
          <Chip
            label="Product Url"
            component="a"
            variant="outlined"
            clickable
            className="add_field text-font"
            onClick={() => handleClick(postState?.addPostData[postStateIndex]?.description === '' ? `${props?.productData?.product_url}` : `\n${props?.productData?.product_url}`)}
          />
          <Chip
            label="Price"
            component="a"
            variant="outlined"
            clickable
            className="add_field text-font"
            onClick={() => handleClick(postState?.addPostData[postStateIndex]?.description === '' ? `${props?.productData?.price}` : `\n${props?.productData?.price}`)}
          />
          <Chip
            label="Clear"
            component="a"
            variant="outlined"
            clickable
            className="add_field text-font"
            onClick={handleClean}
          />
          <Chip
            className="add_field_emoji"
            icon={<IconButton className="emoji_picker" onClick={() => setPicker(!picker)}><EmojiEmotions /></IconButton>}
          />
          {/* <Chip
            icon={<EmojiEmotions />}
            component="a"
            variant="outlined"
            clickable
            className="add_field text-font"
            onClick={() => setPicker(!picker)}
          /> */}
        </Stack>
      </Box>
    </MyDiv>
  )
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(RichTextEditor)
