import * as React from 'react'
// import TextareaAutosize from '@mui/base/TextareaAutosize'
import MyDiv from './customTextArea.style'

export default function CustomTextArea(props) {
  return (
    <MyDiv>
      <div className={props.error ? 'mb-0' : 'mb-2 placeholder_text'}>
        <textarea
          value={props.value}
          onChange={props.onChange}
          rows={props.minRows}
          // cols={5}
          name={props.name}
          placeholder={props.fieldLabel}
          className="custom_textarea"
        />
        {props.error && <div className="error">{props.error}</div>}
      </div>
    </MyDiv>
  )
}
